import {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../../_metronic/helpers'
import usePostApi from '../../custom_hooks/usePostApi'
import clsx from 'clsx'
import {useThemeMode} from '../../../../_metronic/partials'
import {useIntl} from 'react-intl'
import React from 'react'
import {useParams} from 'react-router-dom'
import {UseToast} from '../../useToast'
import Select, {components} from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Swal from 'sweetalert2'
type Data = {
  title_ar: string
  title_ar_phonetic: string
  title_en: string
  title_en_phonetic: string
  section_id: number | string
  category_id: number | string
  sub_category_id: number | string
  content_ar: string
  content_ar_phonetic: string
  content_en: string
  content_en_phonetic: string
  media_type: string
  media_id: string | number
  voice_ar_type: string
  voice_ar: string
  voice_en_type: string
  voice_en: string
  status: number | string
  start_ts: string
  end_ts: string
  tags_ar: string
  tags_en: string
  article_id: number
}
const registrationSchema = Yup.object().shape({
  section_id: Yup.string().required('Section is Required'),
  category_id: Yup.string().required('Category is Required'),
  sub_category_id: Yup.string().required('Sub Category is Required'),
  media_id: Yup.string().required('Media is Required'),
  media_type: Yup.string().required('Media type is Required'),
})

function EditArticle2({closeModal = () => {}, articledata, getarticles}: any) {
  const [categories, setcategory] = useState([])
  const [subcategories, setsubcategory] = useState([])
  const [mediaid, setmediaid] = useState([])
  const [categoriesoption, setcategoriesoption] = useState('')
  const [subcategoriesoption, setsubcategoriesoption] = useState('')
  const [mediaidoption, setmediaidoption] = useState('')
  const articleStatus = articledata?.status?.toString()

  const getCategory = async () => {
    try {
      const url = `/category/section_id/get`
      const formData = {
        section_id: articledata?.section_id,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setcategory(options)

      if (options.length === 1) {
        setcategoriesoption(options[0].value)
      } else {
        setcategoriesoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCategory()
  }, [])

  const getsubCategory = async () => {
    try {
      const url = `/subcategory/get/category_id`
      const formData = {
        category_id: articledata?.category_id,
      }

      const response = await execute(url, 'POST', formData)

      const options = (response.data || []).map((each: any) => ({
        value: each.sub_category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))

      setsubcategory(options)

      if (options.length === 1) {
        setsubcategoriesoption(options[0].value)
      } else {
        setsubcategoriesoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getsubCategory()
  }, [])

  const getMediatype = async () => {
    try {
      const url = `/media/get/all`
      const formData = {
        media_type: articledata?.media_type,
      }

      const response = await execute(url, 'POST', formData)

      const options = (response.data || []).map((each: any) => ({
        value: each.media_id,
        label: `${each.media_title_ar} - ${each.media_title_en}`,
      }))
      setmediaid(options)

      if (options.length === 1) {
        setmediaidoption(options[0].value)
      } else {
        setmediaidoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype()
  }, [])
  const [data, setData] = useState<Data>({
    title_ar: articledata?.title_ar || '',
    title_ar_phonetic: articledata?.title_ar_phonetic || '',
    title_en: articledata?.title_en || '',
    title_en_phonetic: articledata?.title_en_phonetic || '',
    section_id: articledata?.section_id || '',
    category_id: articledata?.category_id || '',
    sub_category_id: articledata?.sub_category_id || '',
    content_ar: articledata?.content_ar || '',
    content_ar_phonetic: articledata?.content_ar_phonetic || '',
    content_en: articledata?.content_en || '',
    content_en_phonetic: articledata?.content_en_phonetic || '',
    media_type: articledata?.media_type || '',
    media_id: articledata?.media_id || '',
    voice_ar_type: articledata?.voice_ar_type || '',
    voice_ar: articledata?.voice_ar || '',
    voice_en_type: articledata?.voice_en_type || '',
    voice_en: articledata?.voice_en || '',
    status: articleStatus || '',
    start_ts: articledata?.start_ts || '',
    end_ts: articledata?.end_ts || '',
    tags_ar: articledata?.tags_ar || '',
    tags_en: articledata?.tags_en || '',
    article_id: articledata?.article_id,
  })
  const [currentStep, setCurrentStep] = useState(0)
  const set2ndIndex = () => {
    setCurrentStep(1)
  }

  const set3rdIndex = () => {
    setCurrentStep(2)
  }

  const set4thIndex = () => {
    setCurrentStep(3)
  }
  const intl = useIntl()
  const {execute, postLoading} = usePostApi()
  function formatDateToMySQL(dateString: any) {
    const date = new Date(dateString)

    const pad = (num: any) => (num < 10 ? `0${num}` : num) // Padding function for single-digit numbers

    // Formatting the date components
    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1) // Months are zero-indexed
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    const milliseconds = String(date.getMilliseconds()).padStart(6, '0') // Ensure 6-digit precision

    // Construct the formatted string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

    return formattedDate
  }

  const handleSaveClick = async () => {
    try {
      const url = `/articles/update`
      const result = await Swal.fire({
        title: 'Updating the Article Information',
        text: 'Press the Confirm button to update the Article Information.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const formdata = {
          title_ar: data.title_ar,
          title_ar_phonetic: data.title_ar_phonetic,
          title_en: data.title_en ? data.title_en : '',
          title_en_phonetic: data.title_en_phonetic ? data.title_en_phonetic : '',
          section_id: data.section_id,
          category_id: data.category_id,
          sub_category_id: data.sub_category_id,
          content_ar: data.content_ar,
          content_ar_phonetic: data.content_ar_phonetic,
          content_en: data.content_en ? data.content_en : '',
          content_en_phonetic: data.content_en_phonetic ? data.content_en_phonetic : '',
          media_type: data.media_type,
          media_id: data.media_id,
          voice_ar_type: data.voice_ar_type,
          voice_ar: data.voice_ar,
          voice_en_type: data.voice_en_type,
          voice_en: data.voice_en,
          status: data.status,
          start_ts: data.start_ts,
          end_ts: data.end_ts,
          tags_ar: data.tags_ar,
          tags_en: data.tags_en ? data.tags_en : '',
          article_id: data.article_id,
        }
        const response = await execute(url, 'POST', formdata)
        closeModal()
        getarticles()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      }
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1
      set2ndIndex={set2ndIndex}
      data={data}
      setData={setData}
      Categories={categories}
      subCategory={subcategories}
      mediaId={mediaid}
    />,
    <Step2 set3rdIndex={set3rdIndex} data={data} setData={setData} />,
    <Step3 data={data} setData={setData} set4thIndex={set4thIndex} />,
    <Step4 data={data} setData={setData} handleSaveClick={handleSaveClick} />,
  ]

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      const element = document.getElementById('step_4_submit')
      if (element) {
        element.click()
      }
    } else if (currentStep === steps.length - 1) {
      const element = document.getElementById('step_3_submit')
      if (element) {
        element.click()
      }
    }
    if (currentStep === 0) {
      const element = document.getElementById('verify-sign-up-btn')
      if (element) {
        element.click()
      }
    } else if (currentStep === 1) {
      const element = document.getElementById('step_2_submit')
      if (element) {
        element.click()
      }
    } else {
      const totalSteps = steps.length - 1
      if (currentStep < totalSteps) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <>
      <div className='card py-0 w-100'>
        <div className='w-100 m-auto min-h-500px'>
          <div className='d-flex justify-content-between mb-10'>
            <h4 className='fw-semibold'>Edit Article</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={closeModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
          {/* <div className='w-100 d-flex flex-column flex-lg-row justify-content-between'> */}

          <>
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted mb-0', {
                          'text-muted fw-semibold': currentStep >= 0,
                        })}
                      >
                        Article Info
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold text-muted', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted mb-0', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Title Info
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>

                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 2,
                          'btn-light-primary': currentStep !== 2,
                        }
                      )}
                    >
                      {currentStep > 2 ? <i className='stepper-check fas fa-check'></i> : 3}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold text-muted', {
                          'text-muted': currentStep >= 2,
                        })}
                      >
                        Step 3
                      </h6>
                      <p
                        className={clsx('fs-6 fw-semibold text-muted', {
                          'text-muted': currentStep > 1,
                        })}
                      >
                        Content Info
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 3,
                          'btn-light-primary': currentStep !== 3,
                        }
                      )}
                    >
                      {currentStep > 3 ? <i className='stepper-check fas fa-check'></i> : 4}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold text-muted', {
                          'text-muted': currentStep >= 3,
                        })}
                      >
                        Step 4
                      </h6>
                      <p
                        className={clsx('fs-6 fw-semibold text-muted', {
                          'text-muted': currentStep > 1,
                        })}
                      >
                        Additional Info
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>
          </>

          <div className='w-100 d-flex flex-stack mt-5'>
            <div className='mr-2'>
              {currentStep > 0 && (
                <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              )}
            </div>

            <div>
              <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                {currentStep !== steps.length - 1 ? 'Continue' : 'Edit Article'}
                <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Step1 = (props: any) => {
  const onSubmit = (values: any) => {
    props.setData({...values})
    props.set2ndIndex()
  }

  const {execute} = usePostApi()
  const intl = useIntl()
  const {mode} = useThemeMode()

  const [section, setsection] = useState([])

  const [sectionoption, setsectionoption] = useState('')
  const [filteredcategories, setfilteredcategory] = useState([])
  const [filteredsubcategories, setfilteredsubcategory] = useState([])
  const [filteredmedia, setfilteredmedia] = useState([])
  const mediaid = props.mediaId
  const [selectedSectionId, setSelectedSectionId] = useState('') // State for selected section ID
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const categories = props.Categories
  const subcategories = props.subCategory
  const [selectedMediatype, setselectedmediatype] = useState('')
  const getSections = async () => {
    try {
      const url = `/section/get/all`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.section_id,
        label: `${each.section_name_ar} - ${each.section_name_en}`,
      }))
      setsection(options)

      if (options.length === 1) {
        setsectionoption(options[0].value)
      } else {
        setsectionoption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getSections()
  }, [])

  const handleSectionChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('section_id', selectedOption.value)
      setSelectedSectionId(selectedOption.value)
    } else {
      setFieldValue('section_id', '')
      setSelectedSectionId('') // Clear section ID
    }
  }

  // Handle category change
  const handleCategoryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('category_id', selectedOption.value)
      setSelectedCategoryId(selectedOption.value) // Update state with selected category ID
    } else {
      setFieldValue('category_id', '')
      setSelectedCategoryId('') // Clear category ID
    }
  }

  const handlemediatypeChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('media_id', selectedOption.value)
    } else {
      setFieldValue('media_id', '') // Handle case where no option is selected
    }
  }

  const handlesubcategoryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('sub_category_id', selectedOption.value)
    } else {
      setFieldValue('sub_category_id', '') // Handle case where no option is selected
    }
  }

  const getCategory = async () => {
    try {
      const url = `/category/section_id/get`
      const formData = {
        section_id: selectedSectionId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setfilteredcategory(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCategory()
  }, [])

  const getsubCategory = async () => {
    try {
      const url = `/subcategory/get/category_id`
      const formData = {
        category_id: selectedCategoryId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.sub_category_id,
        label: `${each.category_name_ar} - ${each.category_name_en}`,
      }))
      setfilteredsubcategory(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getsubCategory()
  }, [])

  useEffect(() => {
    if (selectedSectionId) {
      getCategory()
    }
  }, [selectedSectionId]) // Trigger when selectedSectionId changes

  // Fetch subcategories when category_id changes
  useEffect(() => {
    if (selectedCategoryId) {
      getsubCategory()
    }
  }, [selectedCategoryId])

  const handleMediaTypeChange = (event: any) => {
    const value = event.target.value
    setselectedmediatype(value) // Set selected media type
  }

  const getMediatype = async (selectedMediatype: any) => {
    try {
      const url = `/media/get/all`
      const formData = {
        media_type: selectedMediatype,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.media_id,
        label: `${each.media_title_ar} - ${each.media_title_en}`,
      }))
      setfilteredmedia(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMediatype(selectedMediatype)
  }, [])

  useEffect(() => {
    if (selectedMediatype) {
      getMediatype(selectedMediatype)
    }
  }, [selectedMediatype])

  return (
    <>
      <Formik
        validationSchema={registrationSchema}
        initialValues={{
          ...props.data,
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, values, handleChange}) => {
          return (
            <Form className=''>
              <>
                <div className='w-100 mb-5 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Section</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select Section'
                    ></i>
                  </label>
                  <Select
                    value={section?.find((option: any) => option.value === values?.section_id)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        color: '#ffffff',
                        borderWidth: '0px',
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000',
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                      }),
                      option: (baseStyles, {isFocused, isSelected}) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? '#C2C2C2'
                          : isFocused
                          ? '#e0e0e0'
                          : baseStyles.backgroundColor,
                        color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      backgroundColor: '#000000',
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(80, 107, 236, 0.5)',
                        primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      },
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={section}
                    isSearchable
                    placeholder='Select Section'
                    onChange={(val) => handleSectionChange(setFieldValue, val)}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='section_id' />
                  </div>
                </div>

                <div className='d-flex'>
                  {selectedSectionId ? (
                    <div className='w-100 mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Select Category</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Select Category'
                        ></i>
                      </label>

                      <Select
                        value={filteredcategories?.find(
                          (option: any) => option.value === values.category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-1'
                        classNamePrefix='react-select'
                        options={filteredcategories}
                        isSearchable
                        placeholder='Select Category'
                        onChange={(val) => handleCategoryChange(setFieldValue, val)}
                      />

                      <div className='text-danger mt-2'>
                        <ErrorMessage name='category_id' />
                      </div>
                    </div>
                  ) : (
                    <div className='w-100 mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Select Category</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Select Category'
                        ></i>
                      </label>

                      <Select
                        value={categories?.find(
                          (option: any) => option.value === values.category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-1'
                        classNamePrefix='react-select'
                        options={categories}
                        isSearchable
                        placeholder='Select Category'
                        onChange={(val) => handleCategoryChange(setFieldValue, val)}
                      />

                      <div className='text-danger mt-2'>
                        <ErrorMessage name='category_id' />
                      </div>
                    </div>
                  )}

                  {selectedCategoryId ? (
                    <div className='w-100 mb-4 me-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Select Sub Category</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Select Sub Category'
                        ></i>
                      </label>

                      <Select
                        value={filteredsubcategories?.find(
                          (option: any) => option.value === values.sub_category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-8'
                        classNamePrefix='react-select'
                        options={filteredsubcategories}
                        isSearchable
                        placeholder='Select Sub Category'
                        onChange={(val) => handlesubcategoryChange(setFieldValue, val)}
                      />
                    </div>
                  ) : (
                    <div className='w-100 mb-4 me-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Select Sub Category</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Select Sub Category'
                        ></i>
                      </label>

                      <Select
                        value={subcategories?.find(
                          (option: any) => option.value === values.sub_category_id
                        )}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container me-8'
                        classNamePrefix='react-select'
                        options={subcategories}
                        isSearchable
                        placeholder='Select Sub Category'
                        onChange={(val) => handlesubcategoryChange(setFieldValue, val)}
                      />
                    </div>
                  )}
                </div>

                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Media type</span>
                    <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                  </label>

                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderMale'
                      name='media_type'
                      value='A'
                      checked={values.media_type === 'A'} // Check if the value is 'M' (Male)
                      onChange={(event) => {
                        handleChange(event) // This updates the form state
                        handleMediaTypeChange(event) // This updates selectedMediatype
                      }}
                    />
                    <label className='form-check-label fs-6' htmlFor='genderMale'>
                      Audio
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderFemale'
                      name='media_type'
                      value='V'
                      checked={values.media_type === 'V'} // Check if the value is 'F' (Female)
                      onChange={(event) => {
                        handleChange(event) // This updates the form state
                        handleMediaTypeChange(event) // This updates selectedMediatype
                      }}
                    />
                    <label className='form-check-label fs-6' htmlFor='genderFemale'>
                      Video
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderOthers'
                      name='media_type'
                      value='P'
                      checked={values.media_type === 'P'}
                      onChange={(event) => {
                        handleChange(event) // This updates the form state
                        handleMediaTypeChange(event) // This updates selectedMediatype
                      }}
                    />
                    <label className='form-check-label fs-6' htmlFor='genderOthers'>
                      Picture
                    </label>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='media_type' />
                  </div>
                </div>
                {selectedMediatype ? (
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Media</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Media'
                      ></i>
                    </label>

                    <Select
                      value={filteredmedia.find((option: any) => option.value === values.media_id)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles, {isFocused, isSelected}) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container me-6'
                      classNamePrefix='react-select'
                      options={filteredmedia}
                      isSearchable
                      placeholder='Select Media'
                      onChange={(val) => handlemediatypeChange(setFieldValue, val)}
                    />
                    {/* <button
                      type='button'
                      className='btn btn-icon btn-light btn-sm ms-1'
                      data-bs-toggle='tooltip'
                      title='upload media'
                      onClick={() => setShowAddMediaModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button> */}

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='media_id' />
                    </div>
                  </div>
                ) : (
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Media</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Media'
                      ></i>
                    </label>

                    <Select
                      value={mediaid.find((option: any) => option.value === values.media_id)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles, {isFocused, isSelected}) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container me-6'
                      classNamePrefix='react-select'
                      options={mediaid}
                      isSearchable
                      placeholder='Select Media'
                      onChange={(val) => handlemediatypeChange(setFieldValue, val)}
                    />
                    {/* <button
                      type='button'
                      className='btn btn-icon btn-light btn-sm ms-1'
                      data-bs-toggle='tooltip'
                      title='upload media'
                      onClick={() => setShowAddMediaModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                    </button> */}

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='media_id' />
                    </div>
                  </div>
                )}

                <button
                  type='submit'
                  className='d-none btn btn-lg btn-primary w-100'
                  id='verify-sign-up-btn'
                >
                  Submit
                </button>
              </>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const step2Schema = Yup.object().shape({
  title_ar: Yup.string().required('Title is Required.'),
  title_ar_phonetic: Yup.string().required('Title phonetic (Arabic) is Required.'),
  // content_ar: Yup.string().required('Content is Required.'),
  // content_ar_phonetic: Yup.string().required('Content phonetic (Arabic) is Required.'),
})

const Step2 = ({data, setData, set3rdIndex}: any) => {
  const {mode} = useThemeMode()

  const onSubmit = (values: any) => {
    setData({...data, ...values})
    set3rdIndex()
  }

  return (
    <Formik initialValues={data} validationSchema={step2Schema} onSubmit={onSubmit}>
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5 me-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Title (Arabic)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter title (ar)'
                ></i>
              </label>
              <Field
                type='text'
                name='title_ar'
                className='form-control form-control-solid'
                placeholder='أدخل العنوان
                         '
                dir='rtl'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_ar' />
              </div>
            </div>

            <div className='w-100 mb-5 me-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Title Phonetic (Arabic)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter title (ar)'
                ></i>
              </label>
              <Field
                name='title_ar_phonetic'
                className='form-control form-control-solid'
                placeholder='أدخل العنوان الصوتي
                            '
                dir='rtl'
                type='text'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_ar_phonetic' />
              </div>
            </div>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Title (English)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter title (en)'
                ></i>
              </label>
              <Field
                type='text'
                name='title_en'
                className='form-control form-control-solid'
                placeholder='Enter title'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_en' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Title Phonetic (English)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter title (en)'
                ></i>
              </label>
              <Field
                type='text'
                name='title_en_phonetic'
                className='form-control form-control-solid'
                placeholder='Enter title phonetic'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='title_en_phonetic' />
              </div>
            </div>

            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step_2_submit'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

const step3Schema = Yup.object().shape({
  content_ar: Yup.string().required('Content is Required.'),
  content_ar_phonetic: Yup.string().required('Content phonetic (Arabic) is Required.'),
})

const Step3 = ({data, setData, set4thIndex}: any) => {
  const onSubmit = (values: any) => {
    setData({...data, ...values})
    set4thIndex()
  }

  const updateData = (key: any, val: any) => {
    setData({...data, [key]: val})
  }

  return (
    <Formik initialValues={data} validationSchema={step3Schema} onSubmit={onSubmit}>
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Content (Arabic)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content (ar)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_ar'
                  className='form-control form-control-solid'
                  placeholder='أدخل المحتوى'
                  onChange={(e: any) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_ar', e.target.value)
                  }}
                  dir='rtl'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_ar' />
                </div>
              </div>

              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Content Phonetic (Arabic)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content (ar)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_ar_phonetic'
                  className='form-control form-control-solid'
                  placeholder='أدخل المحتوى الصوتي'
                  onChange={(e: any) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_ar_phonetic', e.target.value)
                  }}
                  dir='rtl'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_ar_phonetic' />
                </div>
              </div>

              <div className='w-100 mb-5 ms-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Content (English)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content (en)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_en'
                  className='form-control form-control-solid'
                  placeholder='Enter Content (en)'
                  onChange={(e: any) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_en', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_en' />
                </div>
              </div>

              <div className='w-100 mb-5 ms-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Content Phonetic (English)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Content phonetic (en)'
                  ></i>
                </label>
                <Field
                  as='textarea'
                  type='text'
                  name='content_en_phonetic'
                  className='form-control form-control-solid'
                  placeholder='Enter Content phonetic'
                  onChange={(e: any) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('content_en_phonetic', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='content_en_phonetic' />
                </div>
              </div>

              <button className='d-none btn btn-light-primary' type='submit' id='step_3_submit'>
                Submit
              </button>
            </>
          </Form>
        )
      }}
    </Formik>
  )
}

const step4Schema = Yup.object().shape({
  voice_ar_type: Yup.string().required('Voice type (Arabic) is Required.'),
  start_ts: Yup.string().required('Start Date is Required.'),
  end_ts: Yup.string()
    .required('End Date is Required.')
    .test('is-greater', 'End Date must be greater than Start Date', function (value) {
      const {start_ts} = this.parent // Access the start_ts value
      return value && start_ts ? new Date(value) > new Date(start_ts) : true
    }),

  tags_ar: Yup.string().required('Tags (Arabic) is Required.'),
})

const Step4 = ({data, setData, handleSaveClick}: any) => {
  const {mode} = useThemeMode()
  const [locale, setLocale] = React.useState('en')
  const onSubmit = (values: any) => {
    setData({...data, ...values})
    handleSaveClick()
  }

  const updateData = (key: any, val: any) => {
    setData({...data, [key]: val})
  }

  return (
    <Formik initialValues={data} validationSchema={step4Schema} onSubmit={onSubmit}>
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <>
              <div className='d-flex'>
                <div className='w-100 mb-5 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Created Date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select Date and time'
                    ></i>
                  </label>
                  <Field name='start_ts'>
                    {({form, field}: any) => {
                      const {setFieldValue} = form
                      const {value} = field

                      const handleChange = (date: any) => {
                        setFieldValue('start_ts', date) // Set the date directly
                        updateData('start_ts', date) // Use the correct key to update data
                      }

                      return (
                        <div
                          className={`react-datepicker-fullwidth-wrapper test ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            selected={value}
                            minDate={new Date()}
                            showYearDropdown
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='dd/MM/yyyy h:mm aa'
                            onChange={handleChange}
                            placeholderText='Select Date and Time'
                            className='form-control form-control-solid'
                            popperPlacement='bottom'
                            locale={locale}
                          />
                        </div>
                      )
                    }}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='start_ts' />
                  </div>
                </div>

                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>End Date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select Date and time'
                    ></i>
                  </label>
                  <Field name='end_ts'>
                    {({form, field}: any) => {
                      const {setFieldValue} = form
                      const {value} = field

                      const handleChange = (date: any) => {
                        setFieldValue('end_ts', date) // Set the date directly
                        updateData('end_ts', date) // Use the correct key to update data
                      }
                      const currentDate = new Date()
                      const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1)) // Add one day to the current date

                      return (
                        <div
                          className={`react-datepicker-fullwidth-wrapper test ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            selected={value}
                            minDate={tomorrow}
                            showYearDropdown
                            scrollableYearDropdown
                            showMonthDropdown
                            useShortMonthInDropdown
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='dd/MM/yyyy h:mm aa'
                            onChange={handleChange}
                            placeholderText='Select Date and Time'
                            className='form-control form-control-solid'
                            popperPlacement='bottom'
                            locale={locale}
                          />
                        </div>
                      )
                    }}
                  </Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='end_ts' />
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Voice type (Arabic)</span>
                    <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                  </label>

                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderMale'
                      name='voice_ar_type'
                      value='TTS'
                      checked={values.voice_ar_type === 'TTS'} // Check if the value is 'M' (Male)
                      onChange={(e) => {
                        updateData(e.target.name, e.target.value)
                        setFieldValue('voice_ar_type', e.target.value)
                      }} // // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderMale'>
                      TTS
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderFemale'
                      name='voice_ar_type'
                      value='REC'
                      checked={values.voice_ar_type === 'REC'} // Check if the value is 'F' (Female)
                      onChange={(e) => {
                        updateData(e.target.name, e.target.value)
                        setFieldValue('voice_ar_type', e.target.value)
                      }} // // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderFemale'>
                      REC
                    </label>
                  </div>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='voice_ar_type' />
                  </div>
                </div>

                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Voice type (English)</span>
                    <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                  </label>

                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderMale'
                      name='voice_en_type'
                      value='TTS'
                      checked={values.voice_en_type === 'TTS'} // Check if the value is 'M' (Male)
                      onChange={(e) => {
                        updateData(e.target.name, e.target.value)
                        setFieldValue('voice_en_type', e.target.value)
                      }} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderMale'>
                      TTS
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='genderFemale'
                      name='voice_en_type'
                      value='REC'
                      checked={values.voice_en_type === 'REC'} // Check if the value is 'F' (Female)
                      onChange={(e) => {
                        updateData(e.target.name, e.target.value)
                        setFieldValue('voice_en_type', e.target.value)
                      }} // Handle change and update Formik state
                    />
                    <label className='form-check-label fs-6' htmlFor='genderFemale'>
                      REC
                    </label>
                  </div>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='media_type' />
                  </div>
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Tags (Arabic)</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter any tag names if you want to specify.'
                  ></i>
                </label>
                <Field
                  id='tags'
                  type='text'
                  name='tags_ar'
                  className='form-control form-control-solid'
                  placeholder='أدخل العلامات مفصولة بفواصل'
                  dir='rtl'
                  onChange={(e: any) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('tags_ar', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='tags_ar' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Tags (English)</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter any tag names if you want to specify.'
                  ></i>
                </label>
                <Field
                  id='tags'
                  type='text'
                  name='tags_en'
                  className='form-control form-control-solid'
                  placeholder="Enter tags separated by comma's"
                  onChange={(e: any) => {
                    updateData(e.target.name, e.target.value)
                    setFieldValue('tags_en', e.target.value)
                  }}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='tags_en' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Status</span>
                  <i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip'></i>
                </label>

                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='active'
                    name='status'
                    value='1'
                    checked={values.status === '1'}
                    onChange={(e) => {
                      updateData(e.target.name, e.target.value)
                      setFieldValue('status', e.target.value)
                    }}
                  />
                  <label className='form-check-label fs-6' htmlFor='active'>
                    Active
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='inactive'
                    name='status'
                    value='0'
                    checked={values.status === '0'}
                    onChange={(e) => {
                      updateData(e.target.name, e.target.value)
                      setFieldValue('status', e.target.value)
                    }}
                  />
                  <label className='form-check-label fs-6' htmlFor='inactive'>
                    Inactive
                  </label>
                </div>

                <div className='text-danger mt-2'>
                  <ErrorMessage name='status' />
                </div>
              </div>
              <button className='d-none btn btn-light-primary' type='submit' id='step_4_submit'>
                Submit
              </button>
            </>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditArticle2
