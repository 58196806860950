import {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import usePostApi from '../custom_hooks/usePostApi'
import {KTIcon} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

function ViewArtcle() {
  const {id} = useParams()
  const navigate = useNavigate()
  const {execute, loading} = usePostApi()
  const [articleData, setArtcleData]: any = useState({})

  const fetchData = async () => {
    try {
      const url = `/articles/get`
      const formData = {article_id: id}
      const response = await execute(url, 'POST', formData)
      setArtcleData(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // console.log(articleData)
  const ImageViewBC = [
    {
      title: `Communities`,
      path: `/discover/manage-topics`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Communities`,
      path: `/discover/manage-topics`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Topics`,
      path: `/discover/manage-topics`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: `Communities`,
      path: `/discover/manage-topics`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${articleData.media_title_en || articleData.media_title_ar}`,
      path: `/discover/manage-topics/${id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const getVideoType = (url: string) => {
    if (!url) return
    // Check if the URL is a YouTube link (both short and long formats)
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
      const videoId = url?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*v=))([\w-]{11})/
      )
      return videoId ? {type: 'youtube', id: videoId[1]} : null
    }

    // Check if the URL is an mp4 link
    if (url?.endsWith('.mp4')) {
      return {type: 'mp4', url}
    }

    return null
  }

  let video: any = getVideoType(articleData?.media_location_url)

  useEffect(() => {
    if (articleData?.media_location_url) {
      video = getVideoType(articleData?.media_location_url)
    }
  }, [articleData])

  const getMedia = () => {
    if (articleData.media_type === 'P') {
      // Render image
      return (
        <img
          src={articleData?.media_location_url}
          alt={articleData?.media_title_en || articleData?.media_title_ar}
          className='w-100 h-100 rounded'
          style={{maxHeight: '400px'}}
        />
      )
    } else if (articleData.media_type === 'A') {
      // Render audio
      return (
        <div className='d-flex justify-content-center'>
          <audio src={articleData.media_location_url} controls />
        </div>
      )
    } else if (articleData.media_type === 'V') {
      // Render video
      if (video?.type === 'youtube') {
        // YouTube video
        return (
          <iframe
            className='w-100 h-400px'
            src={`https://www.youtube.com/embed/${video.id}`}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        )
      } else if (video?.url) {
        // Other video types
        return (
          <video className='w-100' height='415' controls>
            <source src={video.url} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        )
      }
    }

    // Return null if no media type matches
    return null
  }

  return (
    <>
      <PageTitle breadcrumbs={ImageViewBC}>Article Details</PageTitle>

      <div className='d-flex flex-column align-items-center'>
        <div className='w-100 w-md-75'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h3 className='fw-semibold fs-3 mb-5'>File Information</h3>
            <button
              className='btn btn-light-primary btn-sm'
              onClick={() => navigate('/1/communities/manage-topics')}
            >
              <KTIcon iconName='arrow-left' className='fs-2' />
              Back
            </button>
          </div>
          {/* <div className='d-flex flex-column flex-md-row'> */}

          {loading ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <>
              <h3 className='fw-semibold fs-3 mb-2'>{articleData?.media_title_ar}</h3>
              {articleData?.media_title_en && (
                <h3 className='fw-semibold fs-3 mb-2'>({articleData?.media_title_en})</h3>
              )}
              <div className='w-100'>{getMedia()}</div>
              {/* </div> */}
              {/* <h1 className='fw-semibold mt-2 text-right' lang='ar' dir='rtl'>
              {articleData.media_title_ar}
            </h1> */}
              {/* <h2 className='fw-semibold mt-2'>{articleData.media_title_en}</h2> */}

              <div className='d-flex justify-content-between align-items-center mt-4'>
                <div className='d-flex align-items-center'>
                  <KTIcon iconName='calendar' className='fs-3 me-1' />
                  <span>Created: {articleData?.created_ts?.slice(0, 19)}</span>
                </div>
                <div className='d-flex align-items-center'>
                  <KTIcon iconName='message-edit' className='fs-3 me-1' />
                  <span>Updated: {articleData?.updated_ts?.slice(0, 19)}</span>
                </div>
                <div
                  className={`fw-normal badge badge-light-${
                    articleData.status === 1 ? 'success' : 'danger'
                  }`}
                >
                  {articleData?.status === 1 ? 'Active' : 'Inactive'}
                </div>
              </div>

              <div className='mt-5'>
                <h3 className='fw-semibold' dir='rtl'>
                  {articleData.title_ar}
                </h3>
                <p className='text-gray-700' lang='ar' dir='rtl'>
                  {articleData.content_ar}
                </p>
              </div>

              <div className='mt-5'>
                <h3 className='fw-semibold'>{articleData.title_en || '-'}</h3>
                <p className='text-gray-700'>{articleData.content_en || '-'}</p>
              </div>

              {/* <div className='mt-4'>
              <h3 className='fw-semibold fs-3 mb-2'>{articleData?.media_title_en || ''}</h3>
              <p className='text-gray-700'>{articleData?.content_en || '-'}</p>
            </div> */}

              <div className='mb-5'>
                <span className='fw-semibold fs-5'>Category: </span>
                <span className='bg-secondary px-2 py-1 rounded'>
                  {articleData.category_name_ar}{' '}
                  {articleData.category_name_en && <span>({articleData.category_name_en})</span>}
                </span>
              </div>

              <div className='mb-4'>
                <span className='fw-semibold fs-5'>Tags (Ar): </span>
                {articleData?.tags_ar?.split(',').map((tag: string, index: number) => (
                  <span key={index} className='bg-secondary px-2 py-1 rounded me-2 mb-2'>
                    {tag}
                  </span>
                ))}
              </div>

              {articleData?.tags_en && articleData.tags_en.split(',').length > 0 && (
                <div className='mb-4'>
                  <span className='fw-semibold fs-5'>Tags (En): </span>
                  {articleData.tags_en.split(',').map((tag: string, index: number) => (
                    <span key={index} className='bg-secondary px-2 py-1 rounded me-2 mb-2'>
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewArtcle
